import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./css/PromoCodesManager.css";
import LogoSection from "../commons/LogoSection";
import { Loader } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { createPromoCode, updatePromoCode } from "../../graphql/mutations";
import { getCurrentUser } from "aws-amplify/auth";
import { listPromoCodes } from "../../graphql/queries";
import { Modal, Table } from "react-bootstrap";

const PromoCodesManager = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);

  //DB Client
  const client = generateClient();

  // Add new Promocode inputs
  const [newPromocode, setNewPromocode] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [effectiveFrom, setEffectiveFrom] = useState("");
  const [effectiveUpto, setEffectiveUpto] = useState("");

  // common hooks
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  // Modal Hooks and functions.
  const [showModal, setShowModal] = useState(false);
  const [modalState, setModalState] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // All Promocodes
  const [allPromoCodes, setAllPromoCodes] = useState(null);

  // Id to be deactivated
  const [idToBeDeactivated, setIdToBeDeactivated] = useState("");
  const [promocodeToBeDeactivated, setPromocodeToBeDeactivated] = useState("");

  useEffect(() => {
    fetchAllPromocodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllPromocodes = async () => {
    try {
      setLoading(true);
      const response = await client.graphql({ query: listPromoCodes });
      setAllPromoCodes(response.data.listPromoCodes.items);
      setResponseStatus("success");
      setResponseMessage("Promocodes were fetched successfully.");
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage("Error occurred while fetching promocodes.");
      console.error(
        "Error occurred while fetching promocodes." + error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const isValidCouponcode = () => {
    // Promocode will have 4 to 10 upper case characters and 1-2 digit number indicating percentage. Example LUCKY10, HAPPY5
    const regex = /^[A-Z]{4,10}[0-9]{1,2}$/;
    return regex.test(newPromocode);
  };

  const isValidAddPromocodeInputs = () => {
    if (!isValidCouponcode()) {
      setResponseStatus("error");
      setResponseMessage("Invalid Promocode.");
      return false;
    }

    //Discount Percentage should be between 1 to 30. check with owner
    const parsedDiscoutPercentage = parseFloat(discountPercentage);
    if (!(parsedDiscoutPercentage >= 0.01 && parsedDiscoutPercentage <= 0.3)) {
      setResponseStatus("error");
      setResponseMessage("Discount Percentage should be between 0.01 to 0.30.");
      return false;
    }

    // start date and end date validation.
    if (!effectiveFrom) {
      setResponseStatus("error");
      setResponseMessage("Start date cannot be empty.");
      return false;
    }

    if (!effectiveUpto) {
      setResponseStatus("error");
      setResponseMessage("End date cannot be empty.");
      return false;
    }

    return true;
  };

  const getParsedDate = (inputDate) => {
    return new Date(inputDate).toISOString();
  };

  const handleAddPromocode = async () => {
    try {
      setLoading(true);
      if (isValidAddPromocodeInputs()) {
        const { userId } = await getCurrentUser();
        const newPromoCodeInputs = {
          promo_code: newPromocode,
          discount_percentage: parseFloat(discountPercentage),
          effective_from: getParsedDate(effectiveFrom + "  00:00:00"),
          effective_upto: getParsedDate(effectiveUpto + " 23:59:59"),
          is_active: true,
          created_by: userId,
          last_updated_by: userId,
        };
        const newlyAddedPromocode = await client.graphql({
          query: createPromoCode.replaceAll("__typename", ""),
          variables: { input: newPromoCodeInputs },
        });
        setResponseStatus("success");
        setResponseMessage(
          newlyAddedPromocode.data.createPromoCode.promo_code +
            " is added successfully."
        );
        await fetchAllPromocodes();
        resetInputs();
        handleClose();
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(
        "Error occurred while adding promocode. Refer console for more details."
      );
      console.error("Error occurred while adding promocode : ", error.message);
    } finally {
      setLoading(false);
    }
  };

  const resetInputs = () => {
    setNewPromocode("");
    setDiscountPercentage("");
    setEffectiveFrom("");
    setEffectiveUpto("");
    setIdToBeDeactivated("");
    setPromocodeToBeDeactivated("");
  };

  const deactivatePromocode = async (id) => {
    try {
      setLoading(true);
      const updateDetails = {
        id: idToBeDeactivated,
        is_active: false,
      };
      const updatedPromocode = await client.graphql({
        query: updatePromoCode,
        variables: { input: updateDetails },
      });

      setResponseStatus("success");
      setResponseMessage(
        "Promocode " +
          updatedPromocode.data.updatePromoCode.promo_code +
          " was deactivated successfully."
      );
      await fetchAllPromocodes();
      resetInputs();
      handleClose();
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(
        "Error occurred while deactivating Promocode. Refer console."
      );
      console.error(
        "Error occurred while deactivating Promoocde" + error.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="promocodes-top-container">
      <div className="promocodes-manage-container">
        <LogoSection />
        <div className="view-promocodes-section">
          <h6>
            {appCommonData.appLanguage === "English"
              ? "Manage Promocodes"
              : "ப்ரோமோகோடுகளை நிர்வகிக்க"}
          </h6>
          <div className="promo-code-buttons">
            <button type="button" onClick={() => fetchAllPromocodes()}>
              Refresh
            </button>
          </div>

          <Table striped bordered hover responsive="sm">
            <thead>
              <tr>
                <th>Promocode</th>
                <th>Discount Percentage</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Deactivate</th>
              </tr>
            </thead>
            {allPromoCodes && (
              <tbody>
                {allPromoCodes.map((item) => (
                  <tr key={item.id}>
                    <td>{item.promo_code}</td>
                    <td>{item.discount_percentage}</td>
                    <td>
                      {new Date(item.effective_from).toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      })}
                    </td>
                    <td>
                      {new Date(item.effective_upto).toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      })}
                    </td>
                    <td>{item.is_active ? "Active" : "Inactive"}</td>
                    <td>
                      {item.is_active ? (
                        <div className="promo-code-buttons">
                          <button
                            type="button"
                            onClick={() => {
                              setResponseStatus("");
                              setResponseMessage("");
                              resetInputs();
                              setIdToBeDeactivated(item.id);
                              setPromocodeToBeDeactivated(item.promo_code);
                              setModalState("deactivate");
                              handleShow();
                            }}
                          >
                            Deactivate
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
          <div className="promo-code-buttons">
            <button
              type="button"
              onClick={() => {
                setResponseStatus("");
                setResponseMessage("");
                resetInputs();
                setModalState("add");
                handleShow();
              }}
            >
              Add New Promocode
            </button>
          </div>
        </div>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {modalState === "add"
                ? "Add New Promocode"
                : "Deactivate Promocode"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalState === "add" && (
              <>
                <label>
                  Promocode
                  <input
                    type="text"
                    value={newPromocode}
                    onChange={(e) => setNewPromocode(e.target.value)}
                  />
                </label>
                <label>
                  Discount Percentage
                  <input
                    type="number"
                    value={discountPercentage}
                    onChange={(e) => setDiscountPercentage(e.target.value)}
                  />
                </label>
                <label>
                  Effective From
                  <input
                    type="date"
                    value={effectiveFrom}
                    onChange={(e) => setEffectiveFrom(e.target.value)}
                  />
                </label>
                <label>
                  Effective Upto
                  <input
                    type="date"
                    value={effectiveUpto}
                    onChange={(e) => setEffectiveUpto(e.target.value)}
                  />
                </label>
              </>
            )}
            {modalState === "deactivate" && (
              <p>
                {promocodeToBeDeactivated} will be deactivated. Do you want to
                Proceed ?{" "}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="tax-rates-buttons">
              <button
                type="button"
                onClick={() => {
                  modalState === "add"
                    ? handleAddPromocode()
                    : deactivatePromocode();
                }}
              >
                {modalState === "add" ? "Confirm Add" : "Confirm Deactivate"}
              </button>
            </div>
            {isLoading && (
              <div className="loader-container">
                <Loader variation="linear" filledColor="brown" />
              </div>
            )}
            {responseMessage && (
              <div className="response-messages-container">
                <h6
                  style={{
                    color: responseStatus === "success" ? "green" : "red",
                  }}
                >
                  {responseMessage}
                </h6>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {!showModal && isLoading && (
          <div className="loader-container">
            <Loader variation="linear" filledColor="brown" />
          </div>
        )}
        {!showModal && responseMessage && (
          <div className="response-messages-container">
            <h6
              style={{ color: responseStatus === "success" ? "green" : "red" }}
            >
              {responseMessage}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoCodesManager;

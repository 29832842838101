import { generateClient } from "aws-amplify/api";
import {
  getUserByEmailId,
  getUserByPhoneNumber,
} from "../../../graphql/custom-queries";

const client = generateClient();

export const getUserSubIfEmailIdExistsAndVerified = async (email) => {
  try {
    const filter_variables = {
      filter: {
        email_id: {
          eq: email,
        },
      },
    };

    const response = await client.graphql({
      query: getUserByEmailId,
      variables: filter_variables,
      authMode: "apiKey",
    });

    const array = response.data.listUsers.items;
    if (!array[0]) {
      throw new Error(
        "Email Id does not exist. If you registered with your phone, reset your password using phone number."
      );
    }

    if (!array[0].email_id_verification_status) {
      throw new Error(
        "Email Id is not verified. If you registered with your phone, reset your password using phone number."
      );
    }

    return array[0] ? array[0].user_sub : array[0];
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserSubIfPhoneNumberExistsAndVerified = async (
  phone_number
) => {
  try {
    const filter_variables = {
      filter: {
        phone_number: {
          eq: phone_number,
        },
      },
    };

    const response = await client.graphql({
      query: getUserByPhoneNumber,
      variables: filter_variables,
      authMode: "apiKey",
    });
    const array = response.data.listUsers.items;

    if (!array[0]) {
      throw new Error(
        "Phone Number does not exist. If you registered with your Email ID, reset your password using Email ID."
      );
    }

    if (!array[0].phone_number_verification_status) {
      throw new Error(
        "Phone Number is not verified. If you registered with your Email ID, reset your password using Email ID."
      );
    }

    return array[0] ? array[0].user_sub : array[0];
  } catch (error) {
    throw new Error(error.message);
  }
};

export const isEmailUnique = async (email) => {
  try {
    const filter_variables = {
      filter: {
        email_id: {
          eq: email,
        },
      },
    };

    const response = await client.graphql({
      query: getUserByEmailId,
      variables: filter_variables,
      authMode: "apiKey",
    });

    const array = response.data.listUsers.items;

    if (array[0]) {
      throw new Error(
        "User already exists with the given Email Id. Please reset your password to login."
      );
    }
  } catch (error) {
    throw new Error(error.message);
  }
  return true;
};

export const isPhoneNumberUnique = async (phone_number) => {
  try {
    const filter_variables = {
      filter: {
        phone_number: {
          eq: phone_number,
        },
      },
    };

    const response = await client.graphql({
      query: getUserByPhoneNumber,
      variables: filter_variables,
      authMode: "apiKey",
    });
    const array = response.data.listUsers.items;

    if (array[0]) {
      throw new Error(
        "User already exists with the given Phone Number. Please reset your password to login."
      );
    }
  } catch (error) {
    throw new Error(error.message);
  }

  return true;
};

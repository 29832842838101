export const getUserByEmailId = /* GraphQL */ `
  query GetUserByEmailId(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        user_sub
        email_id_verification_status
      }
      nextToken
      __typename
    }
  }
`;

export const getUserByPhoneNumber = /* GraphQL */ `
  query GetUserByPhoneNumber(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        user_sub
        phone_number_verification_status
      }
      nextToken
      __typename
    }
  }
`;

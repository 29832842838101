import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./css/CDRatesManager.css";
import LogoSection from "../commons/LogoSection";
import { Loader } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { createCDRate, updateCDRate } from "../../graphql/mutations";
import { getCurrentUser } from "aws-amplify/auth";
import { listCDRates } from "../../graphql/queries";
import { Modal, Table } from "react-bootstrap";

const CDRatesManager = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);

  //DB Client
  const client = generateClient();

  // Add new CD Rate inputs
  const [cdRate, setCDRate] = useState("");
  const [effectiveFrom, setEffectiveFrom] = useState("");
  const [effectiveUpto, setEffectiveUpto] = useState("");

  //Update hook
  const [idToBeUpdated, setIdToBeUpdated] = useState("");

  // common hooks
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  // Modal Hooks & methods
  const [showModal, setShowModal] = useState(false);
  const [modalState, setModalState] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // All Promocodes
  const [allCDRates, setAllCDRates] = useState(null);

  // First Time Loader
  useEffect(() => {
    fetchAllCDRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllCDRates = async () => {
    try {
      setLoading(true);
      const response = await client.graphql({ query: listCDRates });
      setAllCDRates(response.data.listCDRates.items);
      setResponseStatus("success");
      setResponseMessage("Caution Deposit Rates were fetched successfully.");
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(
        "Error occurred while fetching Caution Deposit Rates."
      );
      console.error(
        "Error occurred while fetching Caution Deposit Rates." + error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const isValidCDRateInputs = () => {
    const parsedCDRate = parseFloat(cdRate);
    if (!(parsedCDRate > 0 && parsedCDRate <= 0.3)) {
      setResponseStatus("error");
      setResponseMessage("CD Rate Percentage should be between 0.01 to 0.3.");
      return false;
    }
    return true;
  };

  const getParsedDate = (inputDate) => {
    return new Date(inputDate).toISOString();
  };

  const formatISODate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleAddCDRate = async () => {
    setResponseStatus("");
    setResponseMessage("");
    try {
      setLoading(true);
      if (isValidCDRateInputs()) {
        const { userId } = await getCurrentUser();
        const newCDRatesInputs = {
          cd_rate: parseFloat(cdRate),
          effective_from: getParsedDate(effectiveFrom + " 00:00:00"),
          effective_upto: effectiveUpto
            ? getParsedDate(effectiveUpto + " 23:59:59")
            : null,
          created_by: userId,
          last_updated_by: userId,
        };
        await client.graphql({
          query: createCDRate.replaceAll("__typename", ""),
          variables: { input: newCDRatesInputs },
        });
        setResponseStatus("success");
        setResponseMessage("New Caution Deposit Rate is added successfully");
        resetInputs();
        handleClose();
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(
        "Error occurred while adding Caution Deposit Rate. Refer console."
      );
      console.error(
        "Error occurred while adding Caution Deposit Rate : ",
        error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCDRate = async () => {
    setResponseStatus("");
    setResponseMessage("");
    try {
      setLoading(true);
      const { userId } = await getCurrentUser();
      const updateCDRatesInputs = {
        id: idToBeUpdated,
        effective_upto: getParsedDate(effectiveUpto + " 23:59:59"),
        created_by: userId,
        last_updated_by: userId,
      };
      await client.graphql({
        query: updateCDRate.replaceAll("__typename", ""),
        variables: { input: updateCDRatesInputs },
      });
      setResponseStatus("success");
      setResponseMessage("Caution Deposit Rate is updated successfully");
      resetInputs();
      handleClose();
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(
        "Error occurred while updating Caution Deposit Rate. Refer console."
      );
      console.error(
        "Error occurred while updating Caution Deposit Rate : ",
        error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const resetInputs = () => {
    setCDRate("");
    setEffectiveFrom("");
    setEffectiveUpto("");
  };

  return (
    <div className="cdrates-top-container">
      <div className="cdrates-manage-container">
        <LogoSection />
        <div className="view-cdrates-section">
          <h6>
            {appCommonData.appLanguage === "English"
              ? "Manage Caution Deposit Rates"
              : "வைப்பு விகிதங்களை நிர்வகிக்க"}
          </h6>
          <div className="cd-rates-buttons">
            <button type="button" onClick={() => fetchAllCDRates()}>
              Refresh
            </button>
          </div>
          <Table striped bordered hover responsive="sm">
            <thead>
              <tr>
                <th>CD Rate</th>
                <th>Effective From</th>
                <th>Effective Upto</th>
                <th>Update</th>
              </tr>
            </thead>
            {allCDRates && (
              <tbody>
                {allCDRates.map((item) => (
                  <tr key={item.id}>
                    <td>{item.cd_rate}</td>
                    <td>
                      {new Date(item.effective_from).toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      })}
                    </td>
                    <td>
                      {item.effective_upto
                        ? new Date(item.effective_upto).toLocaleString(
                            "en-US",
                            { timeZone: "Asia/Kolkata" }
                          )
                        : "Infinity"}
                    </td>
                    <td>
                      <div className="cd-rates-buttons">
                        <button
                          type="button"
                          onClick={() => {
                            setResponseStatus("");
                            setResponseMessage("");
                            resetInputs();
                            setIdToBeUpdated(item.id);
                            setCDRate(item.cd_rate);
                            setEffectiveFrom(
                              formatISODate(item.effective_from)
                            );
                            setEffectiveUpto(
                              item.effective_upto
                                ? formatISODate(item.effective_upto)
                                : ""
                            );
                            setModalState("update");
                            handleShow();
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>

          <div className="cd-rates-buttons">
            <button
              type="button"
              onClick={() => {
                setResponseStatus("");
                setResponseMessage("");
                resetInputs();
                setModalState("add");
                handleShow();
              }}
            >
              Add Caution Deposit Rate
            </button>
          </div>
        </div>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {modalState === "add"
                ? "Add Caution Deposit Rate"
                : "Update Caution Deposit Rate"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(modalState === "add" || modalState === "update") && (
              <>
                <label>
                  Caution Deposit Rate
                  <input
                    type="number"
                    value={cdRate}
                    onChange={(e) => setCDRate(e.target.value)}
                    disabled={modalState === "update" ? true : false}
                  />
                </label>
                <label>
                  Effective From
                  <input
                    type="date"
                    value={effectiveFrom}
                    onChange={(e) => setEffectiveFrom(e.target.value)}
                    disabled={modalState === "update" ? true : false}
                  />
                </label>
                <label>
                  {modalState === "update"
                    ? "Effective Upto"
                    : "Effective Upto (Optional)"}
                  <input
                    type="date"
                    value={effectiveUpto}
                    onChange={(e) => setEffectiveUpto(e.target.value)}
                  />
                </label>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="tax-rates-buttons">
              <button
                type="button"
                onClick={() => {
                  modalState === "update"
                    ? handleUpdateCDRate()
                    : handleAddCDRate();
                }}
              >
                {modalState === "update" ? "Confirm Update" : "Confirm Add"}
              </button>
            </div>
            {isLoading && (
              <div className="loader-container">
                <Loader variation="linear" filledColor="brown" />
              </div>
            )}
            {responseMessage && (
              <div className="response-messages-container">
                <h6
                  style={{
                    color: responseStatus === "success" ? "green" : "red",
                  }}
                >
                  {responseMessage}
                </h6>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {!showModal && isLoading && (
          <div className="loader-container">
            <Loader variation="linear" filledColor="brown" />
          </div>
        )}
        {!showModal && responseMessage && (
          <div className="response-messages-container">
            <h6
              style={{ color: responseStatus === "success" ? "green" : "red" }}
            >
              {responseMessage}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default CDRatesManager;

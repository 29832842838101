import React from "react";
import AppNavbar from "./components/navbar/AppNavbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./components/landing/Landing";
import Login from "./components/auth/Login";
import MyAccount from "./components/auth/MyAccount";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import { Authenticator } from "@aws-amplify/ui-react";
import AnonymousRoute from "./components/auth/AnonymousRoute";
import "./App.css";
import ChangePassword from "./components/auth/ChangePassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import StayBooking from "./components/book/StayBooking";
import PromoCodesManager from "./components/promocodes/PromoCodesManager";
import TaxRateManager from "./components/taxrates/TaxRateManager";
import CDRatesManager from "./components/cdrates/CDRatesManger";
import RoomManager from "./components/rooms/RoomManager";

function App() {
  return (
    <Router>
      <AppNavbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
          element={
            <>
              <Authenticator />
              <AnonymousRoute />
            </>
          }
        >
          <Route path="/login" element={<Login />} />
          <Route path="/resetPassword" element={<ForgotPassword />} />
        </Route>
        <Route
          element={
            <>
              <Authenticator />
              <ProtectedRoute />
            </>
          }
        >
          <Route path="/account" element={<MyAccount />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/book" element={<StayBooking />} />
          <Route path="/promocodes" element={<PromoCodesManager />} />
          <Route path="/taxrates" element={<TaxRateManager />} />
          <Route path="/cdrates" element={<CDRatesManager />} />
          <Route path="/rooms" element={<RoomManager />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

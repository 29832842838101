import React, { useState } from "react";
import "./css/ForgotPassword.css";
import LogoSection from "../commons/LogoSection";
import { useSelector } from "react-redux";
import { Loader } from "@aws-amplify/ui-react";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import {
  getUserSubIfEmailIdExistsAndVerified,
  getUserSubIfPhoneNumberExistsAndVerified,
} from "./util/AccountPresenceChecker";
import { PhoneInput } from "react-international-phone";
import { isPhoneValid } from "./util/InputValidator";

const ForgotPassword = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const navigate = useNavigate();

  //reset password inputs
  const [isEmail, setIsEmail] = useState(true);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userSub, setUserSub] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setPassword] = useState("");
  const [confirmPassword, setConfrmPassword] = useState("");

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const sixDigitRegex = /\d{6}/;

  //Commons
  const [isLoading, setLoading] = useState(false);

  const [isConfirmCodeSent, setConfirmCodeSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  const setEmailButtonActive = () => {
    resetInputs();
    setIsEmail(true);
  };

  const setMobileButtonActive = () => {
    resetInputs();
    setIsEmail(false);
  };

  const resetInputs = () => {
    setEmail("");
    setPhoneNumber("");
    setResponseStatus("");
    setResponseMessage("");
  };

  const isValidResetPasswordInputs = () => {
    if (isEmail) {
      if (!email) {
        setResponseStatus("error");
        setResponseMessage("Email ID cannot be empty.");
        return false;
      }
      if (!emailRegex.test(email)) {
        setResponseStatus("error");
        setResponseMessage("Invalid email address.");
        return false;
      }
    } else {
      if (!phoneNumber) {
        setResponseStatus("error");
        setResponseMessage("Phone Number cannot be empty.");
        return false;
      }
      if (!isPhoneValid(phoneNumber)) {
        setResponseStatus("error");
        setResponseMessage("Invalid Phone Number");
        return false;
      }
    }
    return true;
  };

  const handleResetPassword = async () => {
    try {
      setResponseStatus("");
      setResponseMessage("");
      setLoading(true);
      if (isValidResetPasswordInputs()) {
        const user_sub = isEmail
          ? await getUserSubIfEmailIdExistsAndVerified(email)
          : await getUserSubIfPhoneNumberExistsAndVerified(phoneNumber);

        setUserSub(user_sub);
        const username = user_sub;

        const output = await resetPassword({ username });
        const { nextStep } = output;
        switch (nextStep.resetPasswordStep) {
          case "CONFIRM_RESET_PASSWORD_WITH_CODE":
            const codeDeliveryDetails = nextStep.codeDeliveryDetails;
            setResponseStatus("success");
            setResponseMessage(
              `Confirmation code was sent via ${codeDeliveryDetails.deliveryMedium} to ${codeDeliveryDetails.destination}`
            );
            setConfirmCodeSent(true);
            break;
          case "DONE":
            break;
        }
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const isValidConfirmResetPasswordInputs = () => {
    if (!confirmationCode) {
      setResponseStatus("error");
      setResponseMessage("Confirmation code cannot be empty.");
      return false;
    }
    if (!sixDigitRegex.test(confirmationCode)) {
      setResponseStatus("error");
      setResponseMessage("Confirmation code must be a 6 digit number");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setResponseStatus("error");
      setResponseMessage("Passwords does not match.");
      return false;
    }
    if (newPassword.length < 8 || newPassword.length > 16) {
      setResponseStatus("error");
      setResponseMessage(
        "Password length should be between 8 and 16 characters."
      );
      return false;
    }
    return true;
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleConfirmResetPassword = async () => {
    try {
      setResponseStatus("");
      setResponseMessage("");
      setLoading(true);
      if (isValidConfirmResetPasswordInputs()) {
        const username = userSub;
        await confirmResetPassword({ username, confirmationCode, newPassword });
        setResponseStatus("success");
        setResponseMessage(
          "Password reset successful. Redirecting to login in 3 seconds.."
        );
        await delay(3000);
        navigate("/login");
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-top-container">
      <div className="forgot-password-main-container">
        <LogoSection />
        <h6
          style={{
            fontFamily: "Merriweather",
            margin: "2%",
            textAlign: "center",
          }}
        >
          {appCommonData.appLanguage === "English"
            ? "Reset Password"
            : "கடவுச்சொல் மீட்டமைத்தல்"}
        </h6>
        <div className="forgot-password-choice-container">
          <div className="forgot-password-choice-buttons">
            <button
              className={isEmail ? "active" : ""}
              id="forgot-password-choice-button"
              onClick={setEmailButtonActive}
              type="button"
            >
              {appCommonData.appLanguage === "English"
                ? "Email ID"
                : "மின்னஞ்சல் முகவரி"}
            </button>
            <button
              className={!isEmail ? "active" : ""}
              id="forgot-password-choice-button"
              onClick={setMobileButtonActive}
              type="button"
            >
              {appCommonData.appLanguage === "English"
                ? "Mobile No"
                : "அலைபேசி எண்"}
            </button>
          </div>
        </div>
        <label>
          {isEmail
            ? appCommonData.appLanguage === "English"
              ? "Email ID"
              : "மின்னஞ்சல் முகவரி"
            : appCommonData.appLanguage === "English"
            ? "Mobile No"
            : "அலைபேசி எண்"}
          {isEmail ? (
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          ) : (
            <PhoneInput
              defaultCountry="in"
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
            />
          )}
        </label>
        {!isConfirmCodeSent && (
          <button type="button" onClick={handleResetPassword}>
            {appCommonData.appLanguage === "English"
              ? "Reset Password"
              : "கடவுச்சொல்லை மீட்டமை"}
          </button>
        )}
        {isConfirmCodeSent && (
          <>
            <hr />
            <label>
              {appCommonData.appLanguage === "English"
                ? "Confirmation code"
                : "உறுதிப்படுத்தல் குறியீடு"}
              <input
                type="text"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
              />
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Password"
                : "கடவுச்சொல்"}
              <input
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <label>
              {appCommonData.appLanguage === "English"
                ? "Confirm password"
                : "கடவுச்சொல்லை உறுதிப்படுத்தவும்"}
              <input
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfrmPassword(e.target.value)}
              />
            </label>
            <label>
              <input
                type="checkbox"
                value={showPassword}
                checked={showPassword}
                onChange={handleShowPasswordChange}
              />
              {appCommonData.appLanguage === "English"
                ? " Show password"
                : " கடவுச்சொல்லை காட்டவும்"}
            </label>
            <button type="button" onClick={handleConfirmResetPassword}>
              {appCommonData.appLanguage === "English"
                ? "Reset Password"
                : "கடவுச்சொல்லை மீட்டமை"}
            </button>
          </>
        )}
        {responseMessage && (
          <div className="response-message-container">
            <h6
              style={{ color: responseStatus === "success" ? "green" : "red" }}
            >
              {responseMessage}
            </h6>
          </div>
        )}
        {isLoading && (
          <div>
            <Loader variation="linear" filledColor="brown" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;

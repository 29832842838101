/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_sub
      email_id
      Bookings {
        nextToken
        __typename
      }
      email_id_verification_status
      phone_number
      phone_number_verification_status
      name
      date_of_birth
      user_type
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_sub
        email_id
        email_id_verification_status
        phone_number
        phone_number_verification_status
        name
        date_of_birth
        user_type
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHotel = /* GraphQL */ `
  query GetHotel($id: ID!) {
    getHotel(id: $id) {
      id
      name
      address
      phone_number
      Rooms {
        nextToken
        __typename
      }
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHotels = /* GraphQL */ `
  query ListHotels(
    $filter: ModelHotelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHotels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        phone_number
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      room_number
      type
      Bookings {
        nextToken
        __typename
      }
      capacity
      description
      created_by
      last_updated_by
      hotel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        room_number
        type
        capacity
        description
        created_by
        last_updated_by
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roomsByHotel_id = /* GraphQL */ `
  query RoomsByHotel_id(
    $hotel_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomsByHotel_id(
      hotel_id: $hotel_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        room_number
        type
        capacity
        description
        created_by
        last_updated_by
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrice = /* GraphQL */ `
  query GetPrice($id: ID!) {
    getPrice(id: $id) {
      id
      base_price
      effective_from
      effective_upto
      created_by
      last_updated_by
      price_type
      booking_type
      room_id {
        id
        room_number
        type
        capacity
        description
        created_by
        last_updated_by
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      priceRoom_idId
      __typename
    }
  }
`;
export const listPrices = /* GraphQL */ `
  query ListPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        base_price
        effective_from
        effective_upto
        created_by
        last_updated_by
        price_type
        booking_type
        createdAt
        updatedAt
        priceRoom_idId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      booking_reference_number
      booking_type
      booking_date
      checkin_date
      room_id {
        nextToken
        __typename
      }
      payment_id {
        id
        calculated_base_price
        applied_tax_rate
        applied_cd_rate
        applied_promocode
        applied_promocode_discount
        total_amount
        payment_method
        pg_payment_id
        pg_order_id
        pg_signature
        signature_verification_status
        payment_status
        payment_status_verification_from_pg
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      checkout_date
      booking_status
      user_id
      created_by
      last_updated_by
      createdAt
      updatedAt
      bookingPayment_idId
      __typename
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        booking_reference_number
        booking_type
        booking_date
        checkin_date
        checkout_date
        booking_status
        user_id
        created_by
        last_updated_by
        createdAt
        updatedAt
        bookingPayment_idId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingsByUser_id = /* GraphQL */ `
  query BookingsByUser_id(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByUser_id(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        booking_reference_number
        booking_type
        booking_date
        checkin_date
        checkout_date
        booking_status
        user_id
        created_by
        last_updated_by
        createdAt
        updatedAt
        bookingPayment_idId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      calculated_base_price
      applied_tax_rate
      applied_cd_rate
      applied_promocode
      applied_promocode_discount
      total_amount
      payment_method
      pg_payment_id
      pg_order_id
      pg_signature
      signature_verification_status
      payment_status
      payment_status_verification_from_pg
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        calculated_base_price
        applied_tax_rate
        applied_cd_rate
        applied_promocode
        applied_promocode_discount
        total_amount
        payment_method
        pg_payment_id
        pg_order_id
        pg_signature
        signature_verification_status
        payment_status
        payment_status_verification_from_pg
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaxRate = /* GraphQL */ `
  query GetTaxRate($id: ID!) {
    getTaxRate(id: $id) {
      id
      country_code
      slab_start_price
      slab_end_price
      tax_rate
      effective_from
      effective_upto
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaxRates = /* GraphQL */ `
  query ListTaxRates(
    $filter: ModelTaxRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaxRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country_code
        slab_start_price
        slab_end_price
        tax_rate
        effective_from
        effective_upto
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCDRate = /* GraphQL */ `
  query GetCDRate($id: ID!) {
    getCDRate(id: $id) {
      id
      cd_rate
      effective_from
      effective_upto
      booking_type
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCDRates = /* GraphQL */ `
  query ListCDRates(
    $filter: ModelCDRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCDRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cd_rate
        effective_from
        effective_upto
        booking_type
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPromoCode = /* GraphQL */ `
  query GetPromoCode($id: ID!) {
    getPromoCode(id: $id) {
      id
      promo_code
      discount_percentage
      effective_from
      effective_upto
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPromoCodes = /* GraphQL */ `
  query ListPromoCodes(
    $filter: ModelPromoCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        promo_code
        discount_percentage
        effective_from
        effective_upto
        is_active
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAddonServicePrice = /* GraphQL */ `
  query GetAddonServicePrice($id: ID!) {
    getAddonServicePrice(id: $id) {
      id
      service_name
      price
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAddonServicePrices = /* GraphQL */ `
  query ListAddonServicePrices(
    $filter: ModelAddonServicePriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddonServicePrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service_name
        price
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBookingRoom = /* GraphQL */ `
  query GetBookingRoom($id: ID!) {
    getBookingRoom(id: $id) {
      id
      roomId
      bookingId
      room {
        id
        room_number
        type
        capacity
        description
        created_by
        last_updated_by
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      booking {
        id
        booking_reference_number
        booking_type
        booking_date
        checkin_date
        checkout_date
        booking_status
        user_id
        created_by
        last_updated_by
        createdAt
        updatedAt
        bookingPayment_idId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBookingRooms = /* GraphQL */ `
  query ListBookingRooms(
    $filter: ModelBookingRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roomId
        bookingId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingRoomsByRoomId = /* GraphQL */ `
  query BookingRoomsByRoomId(
    $roomId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingRoomsByRoomId(
      roomId: $roomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roomId
        bookingId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingRoomsByBookingId = /* GraphQL */ `
  query BookingRoomsByBookingId(
    $bookingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingRoomsByBookingId(
      bookingId: $bookingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roomId
        bookingId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import React from "react";
import "./css/RoomManager.css";
import LogoSection from "../commons/LogoSection";

const RoomManager = () => {
  return (
    <div className="room-manager-top-container">
      <div className="room-manager-manage-container">
        <LogoSection />
        <div className="view-rooms-section">
          <h6>Manage Rooms & Prices</h6>
        </div>
      </div>
    </div>
  );
};

export default RoomManager;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./css/TaxRateManager.css";
import LogoSection from "../commons/LogoSection";
import { Loader } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { createTaxRate, updateTaxRate } from "../../graphql/mutations";
import { getCurrentUser } from "aws-amplify/auth";
import { listTaxRates } from "../../graphql/queries";
import { Modal, Table } from "react-bootstrap";

const TaxRateManager = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);

  //DB Client
  const client = generateClient();

  // Add / update new Promocode inputs
  const [countryCode, setCountryCode] = useState("IN");
  const [slabStartPrice, setSlabStartPrice] = useState("");
  const [slabEndPrice, setSlabEndPrice] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [effectiveFrom, setEffectiveFrom] = useState("");
  const [effectiveUpto, setEffectiveUpto] = useState("");

  //Update hook
  const [idToBeUpdated, setIdToBeUpdated] = useState("");

  // common hooks
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  // Modal Hooks
  const [showModal, setShowModal] = useState(false);
  const [modalState, setModalState] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // All Promocodes
  const [allTaxRates, setAllTaxRates] = useState(null);

  useEffect(() => {
    fetchAllTaxRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllTaxRates = async () => {
    try {
      setLoading(true);
      const response = await client.graphql({ query: listTaxRates });
      setAllTaxRates(response.data.listTaxRates.items);
      setResponseStatus("success");
      setResponseMessage("Tax Rates were fetched successfully.");
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage("Error occurred while fetching Tax Rates.");
      console.error("Error occurred while fetching Tax Rates." + error.message);
    } finally {
      setLoading(false);
    }
  };

  const isValidTaxRateInputs = () => {
    //On researching about tax rates for hotels, I found the following documents.
    // https://cleartax.in/s/impact-of-gst-hospitality-industry

    if (parseFloat(slabStartPrice) < 0 || parseFloat(slabEndPrice) < 0) {
      setResponseStatus("error");
      setResponseMessage(
        "Slab Start Price/Slab End Price shouldn't be less than zero."
      );
      return false;
    }

    if (parseFloat(slabStartPrice) >= parseFloat(slabEndPrice)) {
      setResponseStatus("error");
      setResponseMessage(
        "Slab Start Price should be less than slab end price."
      );
      return false;
    }

    const parsedTaxRate = parseFloat(taxRate);
    if (!(parsedTaxRate >= 0.0 && parsedTaxRate <= 0.3)) {
      setResponseStatus("error");
      setResponseMessage("Tax Rate Percentage should be between 0 to 0.30");
      return false;
    }
    return true;
  };

  const getParsedDate = (inputDate) => {
    return new Date(inputDate).toISOString();
  };

  const formatISODate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleAddTaxRate = async () => {
    try {
      setLoading(true);
      if (isValidTaxRateInputs()) {
        const { userId } = await getCurrentUser();
        const newTaxRateInputs = {
          country_code: countryCode,
          slab_start_price: parseFloat(slabStartPrice),
          slab_end_price: parseFloat(slabEndPrice),
          tax_rate: parseFloat(taxRate),
          effective_from: getParsedDate(effectiveFrom + " 00:00:00"),
          effective_upto: effectiveUpto
            ? getParsedDate(effectiveUpto + " 23:59:59")
            : null,
          created_by: userId,
          last_updated_by: userId,
        };
        await client.graphql({
          query: createTaxRate.replaceAll("__typename", ""),
          variables: { input: newTaxRateInputs },
        });
        setResponseStatus("success");
        setResponseMessage(
          "New Tax Rate is added successfully. Click Refresh to see changes"
        );
        resetInputs();
        handleClose();
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(
        "Error occurred while adding tax rate. Refer console."
      );
      console.error("Error occurred while adding tax rate : ", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTaxRate = async () => {
    if (effectiveUpto) {
      try {
        setLoading(true);
        const { userId } = await getCurrentUser();
        const updateTaxRateInputs = {
          id: idToBeUpdated,
          effective_upto: getParsedDate(effectiveUpto + " 23:59:59"),
          last_updated_by: userId,
        };
        await client.graphql({
          query: updateTaxRate.replaceAll("__typename", ""),
          variables: { input: updateTaxRateInputs },
        });
        setResponseStatus("success");
        setResponseMessage(
          "Tax Rate is updated successfully. Click Refresh to see changes"
        );
        resetInputs();
        handleClose();
      } catch (error) {
        setResponseStatus("error");
        setResponseMessage(
          "Error occurred while updating tax rate. Refer console."
        );
        console.error(
          "Error occurred while updating tax rate : ",
          error.message
        );
      } finally {
        setLoading(false);
      }
    } else {
      setResponseStatus("error");
      setResponseMessage("End Date cannot be empty");
    }
  };

  const resetInputs = () => {
    setSlabStartPrice("");
    setSlabEndPrice("");
    setTaxRate("");
    setEffectiveFrom("");
    setEffectiveUpto("");
    setIdToBeUpdated("");
  };

  return (
    <div className="taxrates-top-container">
      <div className="taxrates-manage-container">
        <LogoSection />
        <div className="view-taxrates-section">
          <h6>
            {appCommonData.appLanguage === "English"
              ? "Manage Tax Rates"
              : "வரி விகிதங்களை நிர்வகிக்க"}
          </h6>
          <div className="tax-rates-buttons">
            <button type="button" onClick={() => fetchAllTaxRates()}>
              Refresh
            </button>
          </div>
          <Table striped bordered hover responsive="sm">
            <thead>
              <tr>
                <th>Country Code</th>
                <th>Start Price</th>
                <th>End Price</th>
                <th>Tax Rate</th>
                <th>Effective From</th>
                <th>Effective Upto</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {allTaxRates &&
                allTaxRates.map((item) => (
                  <tr key={item.id}>
                    <td>{item.country_code}</td>
                    <td>{item.slab_start_price}</td>
                    <td>
                      {item.slab_end_price ? item.slab_end_price : "Infinity"}
                    </td>
                    <td>{item.tax_rate}</td>
                    <td>
                      {new Date(item.effective_from).toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      })}
                    </td>
                    <td>
                      {item.effective_upto
                        ? new Date(item.effective_upto).toLocaleString(
                            "en-US",
                            { timeZone: "Asia/Kolkata" }
                          )
                        : "Infinity"}
                    </td>
                    <td>
                      <div className="tax-rates-buttons">
                        <button
                          type="button"
                          onClick={() => {
                            setResponseStatus("");
                            setResponseMessage("");
                            resetInputs();
                            setIdToBeUpdated(item.id);
                            setCountryCode(item.country_code);
                            setSlabStartPrice(item.slab_start_price);
                            setSlabEndPrice(item.slab_end_price);
                            setTaxRate(item.tax_rate);
                            setEffectiveFrom(
                              formatISODate(item.effective_from)
                            );
                            setEffectiveUpto(
                              item.effective_upto
                                ? formatISODate(item.effective_upto)
                                : ""
                            );
                            setModalState("update");
                            handleShow();
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="tax-rates-buttons">
            <button
              type="button"
              onClick={() => {
                setResponseStatus("");
                setResponseMessage("");
                resetInputs();
                setModalState("add");
                handleShow();
              }}
            >
              Add New Slab
            </button>
          </div>
        </div>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {modalState === "add" ? "Add New Tax Slab" : "Update Tax Slab"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(modalState === "add" || modalState === "update") && (
              <>
                <label>
                  Country Code
                  <input
                    type="text"
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    disabled
                  />
                </label>
                <label>
                  Start Price
                  <input
                    type="number"
                    value={slabStartPrice}
                    onChange={(e) => setSlabStartPrice(e.target.value)}
                    disabled={modalState === "update" ? true : false}
                  />
                </label>
                <label>
                  {modalState === "update"
                    ? "End Price"
                    : "End Price (Optional)"}
                  <input
                    type="number"
                    value={slabEndPrice}
                    onChange={(e) => setSlabEndPrice(e.target.value)}
                    disabled={modalState === "update" ? true : false}
                  />
                </label>
                <label>
                  Tax Rate
                  <input
                    type="number"
                    value={taxRate}
                    onChange={(e) => setTaxRate(e.target.value)}
                    disabled={modalState === "update" ? true : false}
                  />
                </label>
                <label>
                  Effective From
                  <input
                    type="date"
                    value={effectiveFrom}
                    onChange={(e) => setEffectiveFrom(e.target.value)}
                    disabled={modalState === "update" ? true : false}
                  />
                </label>
                <label>
                  {modalState === "update"
                    ? "Effective Upto"
                    : "Effective Upto (Optional)"}
                  <input
                    type="date"
                    value={effectiveUpto}
                    onChange={(e) => setEffectiveUpto(e.target.value)}
                  />
                </label>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="tax-rates-buttons">
              <button
                type="button"
                onClick={() => {
                  modalState === "update"
                    ? handleUpdateTaxRate()
                    : handleAddTaxRate();
                }}
              >
                {modalState === "update" ? "Confirm Update" : "Confirm Add"}
              </button>
            </div>
            {isLoading && (
              <div className="loader-container">
                <Loader variation="linear" filledColor="brown" />
              </div>
            )}
            {responseMessage && (
              <div className="response-messages-container">
                <h6
                  style={{
                    color: responseStatus === "success" ? "green" : "red",
                  }}
                >
                  {responseMessage}
                </h6>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {!showModal && isLoading && (
          <div className="loader-container">
            <Loader variation="linear" filledColor="brown" />
          </div>
        )}
        {!showModal && responseMessage && (
          <div className="response-messages-container">
            <h6
              style={{ color: responseStatus === "success" ? "green" : "red" }}
            >
              {responseMessage}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaxRateManager;

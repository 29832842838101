/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      user_sub
      email_id
      Bookings {
        nextToken
        __typename
      }
      email_id_verification_status
      phone_number
      phone_number_verification_status
      name
      date_of_birth
      user_type
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      user_sub
      email_id
      Bookings {
        nextToken
        __typename
      }
      email_id_verification_status
      phone_number
      phone_number_verification_status
      name
      date_of_birth
      user_type
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      user_sub
      email_id
      Bookings {
        nextToken
        __typename
      }
      email_id_verification_status
      phone_number
      phone_number_verification_status
      name
      date_of_birth
      user_type
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHotel = /* GraphQL */ `
  mutation CreateHotel(
    $input: CreateHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    createHotel(input: $input, condition: $condition) {
      id
      name
      address
      phone_number
      Rooms {
        nextToken
        __typename
      }
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHotel = /* GraphQL */ `
  mutation UpdateHotel(
    $input: UpdateHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    updateHotel(input: $input, condition: $condition) {
      id
      name
      address
      phone_number
      Rooms {
        nextToken
        __typename
      }
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHotel = /* GraphQL */ `
  mutation DeleteHotel(
    $input: DeleteHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    deleteHotel(input: $input, condition: $condition) {
      id
      name
      address
      phone_number
      Rooms {
        nextToken
        __typename
      }
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      room_number
      type
      Bookings {
        nextToken
        __typename
      }
      capacity
      description
      created_by
      last_updated_by
      hotel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      room_number
      type
      Bookings {
        nextToken
        __typename
      }
      capacity
      description
      created_by
      last_updated_by
      hotel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      room_number
      type
      Bookings {
        nextToken
        __typename
      }
      capacity
      description
      created_by
      last_updated_by
      hotel_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPrice = /* GraphQL */ `
  mutation CreatePrice(
    $input: CreatePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    createPrice(input: $input, condition: $condition) {
      id
      base_price
      effective_from
      effective_upto
      created_by
      last_updated_by
      price_type
      booking_type
      room_id {
        id
        room_number
        type
        capacity
        description
        created_by
        last_updated_by
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      priceRoom_idId
      __typename
    }
  }
`;
export const updatePrice = /* GraphQL */ `
  mutation UpdatePrice(
    $input: UpdatePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    updatePrice(input: $input, condition: $condition) {
      id
      base_price
      effective_from
      effective_upto
      created_by
      last_updated_by
      price_type
      booking_type
      room_id {
        id
        room_number
        type
        capacity
        description
        created_by
        last_updated_by
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      priceRoom_idId
      __typename
    }
  }
`;
export const deletePrice = /* GraphQL */ `
  mutation DeletePrice(
    $input: DeletePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    deletePrice(input: $input, condition: $condition) {
      id
      base_price
      effective_from
      effective_upto
      created_by
      last_updated_by
      price_type
      booking_type
      room_id {
        id
        room_number
        type
        capacity
        description
        created_by
        last_updated_by
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      priceRoom_idId
      __typename
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      booking_reference_number
      booking_type
      booking_date
      checkin_date
      room_id {
        nextToken
        __typename
      }
      payment_id {
        id
        calculated_base_price
        applied_tax_rate
        applied_cd_rate
        applied_promocode
        applied_promocode_discount
        total_amount
        payment_method
        pg_payment_id
        pg_order_id
        pg_signature
        signature_verification_status
        payment_status
        payment_status_verification_from_pg
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      checkout_date
      booking_status
      user_id
      created_by
      last_updated_by
      createdAt
      updatedAt
      bookingPayment_idId
      __typename
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      booking_reference_number
      booking_type
      booking_date
      checkin_date
      room_id {
        nextToken
        __typename
      }
      payment_id {
        id
        calculated_base_price
        applied_tax_rate
        applied_cd_rate
        applied_promocode
        applied_promocode_discount
        total_amount
        payment_method
        pg_payment_id
        pg_order_id
        pg_signature
        signature_verification_status
        payment_status
        payment_status_verification_from_pg
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      checkout_date
      booking_status
      user_id
      created_by
      last_updated_by
      createdAt
      updatedAt
      bookingPayment_idId
      __typename
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      booking_reference_number
      booking_type
      booking_date
      checkin_date
      room_id {
        nextToken
        __typename
      }
      payment_id {
        id
        calculated_base_price
        applied_tax_rate
        applied_cd_rate
        applied_promocode
        applied_promocode_discount
        total_amount
        payment_method
        pg_payment_id
        pg_order_id
        pg_signature
        signature_verification_status
        payment_status
        payment_status_verification_from_pg
        created_by
        last_updated_by
        createdAt
        updatedAt
        __typename
      }
      checkout_date
      booking_status
      user_id
      created_by
      last_updated_by
      createdAt
      updatedAt
      bookingPayment_idId
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      calculated_base_price
      applied_tax_rate
      applied_cd_rate
      applied_promocode
      applied_promocode_discount
      total_amount
      payment_method
      pg_payment_id
      pg_order_id
      pg_signature
      signature_verification_status
      payment_status
      payment_status_verification_from_pg
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      calculated_base_price
      applied_tax_rate
      applied_cd_rate
      applied_promocode
      applied_promocode_discount
      total_amount
      payment_method
      pg_payment_id
      pg_order_id
      pg_signature
      signature_verification_status
      payment_status
      payment_status_verification_from_pg
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      calculated_base_price
      applied_tax_rate
      applied_cd_rate
      applied_promocode
      applied_promocode_discount
      total_amount
      payment_method
      pg_payment_id
      pg_order_id
      pg_signature
      signature_verification_status
      payment_status
      payment_status_verification_from_pg
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaxRate = /* GraphQL */ `
  mutation CreateTaxRate(
    $input: CreateTaxRateInput!
    $condition: ModelTaxRateConditionInput
  ) {
    createTaxRate(input: $input, condition: $condition) {
      id
      country_code
      slab_start_price
      slab_end_price
      tax_rate
      effective_from
      effective_upto
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaxRate = /* GraphQL */ `
  mutation UpdateTaxRate(
    $input: UpdateTaxRateInput!
    $condition: ModelTaxRateConditionInput
  ) {
    updateTaxRate(input: $input, condition: $condition) {
      id
      country_code
      slab_start_price
      slab_end_price
      tax_rate
      effective_from
      effective_upto
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaxRate = /* GraphQL */ `
  mutation DeleteTaxRate(
    $input: DeleteTaxRateInput!
    $condition: ModelTaxRateConditionInput
  ) {
    deleteTaxRate(input: $input, condition: $condition) {
      id
      country_code
      slab_start_price
      slab_end_price
      tax_rate
      effective_from
      effective_upto
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCDRate = /* GraphQL */ `
  mutation CreateCDRate(
    $input: CreateCDRateInput!
    $condition: ModelCDRateConditionInput
  ) {
    createCDRate(input: $input, condition: $condition) {
      id
      cd_rate
      effective_from
      effective_upto
      booking_type
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCDRate = /* GraphQL */ `
  mutation UpdateCDRate(
    $input: UpdateCDRateInput!
    $condition: ModelCDRateConditionInput
  ) {
    updateCDRate(input: $input, condition: $condition) {
      id
      cd_rate
      effective_from
      effective_upto
      booking_type
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCDRate = /* GraphQL */ `
  mutation DeleteCDRate(
    $input: DeleteCDRateInput!
    $condition: ModelCDRateConditionInput
  ) {
    deleteCDRate(input: $input, condition: $condition) {
      id
      cd_rate
      effective_from
      effective_upto
      booking_type
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPromoCode = /* GraphQL */ `
  mutation CreatePromoCode(
    $input: CreatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    createPromoCode(input: $input, condition: $condition) {
      id
      promo_code
      discount_percentage
      effective_from
      effective_upto
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePromoCode = /* GraphQL */ `
  mutation UpdatePromoCode(
    $input: UpdatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    updatePromoCode(input: $input, condition: $condition) {
      id
      promo_code
      discount_percentage
      effective_from
      effective_upto
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePromoCode = /* GraphQL */ `
  mutation DeletePromoCode(
    $input: DeletePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    deletePromoCode(input: $input, condition: $condition) {
      id
      promo_code
      discount_percentage
      effective_from
      effective_upto
      is_active
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAddonServicePrice = /* GraphQL */ `
  mutation CreateAddonServicePrice(
    $input: CreateAddonServicePriceInput!
    $condition: ModelAddonServicePriceConditionInput
  ) {
    createAddonServicePrice(input: $input, condition: $condition) {
      id
      service_name
      price
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAddonServicePrice = /* GraphQL */ `
  mutation UpdateAddonServicePrice(
    $input: UpdateAddonServicePriceInput!
    $condition: ModelAddonServicePriceConditionInput
  ) {
    updateAddonServicePrice(input: $input, condition: $condition) {
      id
      service_name
      price
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAddonServicePrice = /* GraphQL */ `
  mutation DeleteAddonServicePrice(
    $input: DeleteAddonServicePriceInput!
    $condition: ModelAddonServicePriceConditionInput
  ) {
    deleteAddonServicePrice(input: $input, condition: $condition) {
      id
      service_name
      price
      created_by
      last_updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBookingRoom = /* GraphQL */ `
  mutation CreateBookingRoom(
    $input: CreateBookingRoomInput!
    $condition: ModelBookingRoomConditionInput
  ) {
    createBookingRoom(input: $input, condition: $condition) {
      id
      roomId
      bookingId
      room {
        id
        room_number
        type
        capacity
        description
        created_by
        last_updated_by
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      booking {
        id
        booking_reference_number
        booking_type
        booking_date
        checkin_date
        checkout_date
        booking_status
        user_id
        created_by
        last_updated_by
        createdAt
        updatedAt
        bookingPayment_idId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBookingRoom = /* GraphQL */ `
  mutation UpdateBookingRoom(
    $input: UpdateBookingRoomInput!
    $condition: ModelBookingRoomConditionInput
  ) {
    updateBookingRoom(input: $input, condition: $condition) {
      id
      roomId
      bookingId
      room {
        id
        room_number
        type
        capacity
        description
        created_by
        last_updated_by
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      booking {
        id
        booking_reference_number
        booking_type
        booking_date
        checkin_date
        checkout_date
        booking_status
        user_id
        created_by
        last_updated_by
        createdAt
        updatedAt
        bookingPayment_idId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBookingRoom = /* GraphQL */ `
  mutation DeleteBookingRoom(
    $input: DeleteBookingRoomInput!
    $condition: ModelBookingRoomConditionInput
  ) {
    deleteBookingRoom(input: $input, condition: $condition) {
      id
      roomId
      bookingId
      room {
        id
        room_number
        type
        capacity
        description
        created_by
        last_updated_by
        hotel_id
        createdAt
        updatedAt
        __typename
      }
      booking {
        id
        booking_reference_number
        booking_type
        booking_date
        checkin_date
        checkout_date
        booking_status
        user_id
        created_by
        last_updated_by
        createdAt
        updatedAt
        bookingPayment_idId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

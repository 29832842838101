import { PhoneNumberUtil } from "google-libphonenumber";
import { emailRegex } from "../../../constants/App";

export const isPhoneValid = (phone) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const isValidEmail = (email) => {
  if (!email) {
    throw new Error("Email address cannot be empty.");
  }
  if (!emailRegex.test(email)) {
    throw new Error("Invalid email address");
  }
  return true;
};

export const isValidPhone = (phone) => {
  if (!phone) {
    throw new Error("Phone number cannot be empty.");
  }
  if (!isPhoneValid(phone)) {
    throw new Error("Invalid Phone Number");
  }
  return true;
};

export const isPasswordEmpty = (password) => {
  if (!password) {
    throw new Error("Password can not be empty.");
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader, useAuthenticator } from "@aws-amplify/ui-react";
import "./css/Login.css";
import {
  autoSignIn,
  confirmSignUp,
  fetchAuthSession,
  resendSignUpCode,
  signIn,
  signUp,
} from "aws-amplify/auth";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "@aws-amplify/ui-react/styles.css";
import LogoSection from "../commons/LogoSection";
import { Button } from "react-bootstrap";
import { updateUserRole } from "../../reducers/user/actions";
import {
  getUserSubIfEmailIdExistsAndVerified,
  getUserSubIfPhoneNumberExistsAndVerified,
  isEmailUnique,
  isPhoneNumberUnique,
} from "./util/AccountPresenceChecker";
import {
  isPasswordEmpty,
  isValidEmail,
  isValidPhone,
} from "./util/InputValidator";

const Login = () => {
  const appCommonSelector = (state) => state.appCommon;
  const appCommonData = useSelector(appCommonSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    if (authStatus && authStatus === "authenticated") {
      navigate("/");
    }
  }, [navigate, authStatus]);

  const [isLoading, setLoading] = useState(false);

  const [isLogin, setIsLogin] = useState(true);
  const [isEmail, setIsEmail] = useState(true);

  //Constants
  const nameRegex = /^[a-zA-Z ]*$/;

  //Login Hooks
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPhone, setLoginPhone] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  //Register Form Hooks
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState("");
  const [registerName, setRegisterName] = useState("");
  const [registerBirthDate, setRegisterBirthDate] = useState("");
  const [registerMobileNo, setRegisterMobileNo] = useState("");
  const [signUpConfirmationCode, setSignUpConfirmationCode] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isUniqueUserAttribute, setUniqueUserAttribute] = useState(false);
  const [isConfirmUserDivOpen, setConfirmUserDivOpen] = useState(false);

  //Common Hooks
  const [showPassword, setShowPassword] = useState(false);
  const [userSub, setUserSub] = useState("");

  const setLoginButtonActive = () => {
    resetInputs();
    setIsLogin(true);
  };

  const setRegisterButtonActive = () => {
    resetInputs();
    setIsLogin(false);
  };

  const resetInputs = () => {
    setIsEmail(true);
    //Login
    setLoginEmail("");
    setLoginPassword("");
    setLoginPhone("");
    setUserSub("");

    //Register
    setRegisterEmail("");
    setRegisterPassword("");
    setRegisterConfirmPassword("");
    setRegisterName("");
    setRegisterBirthDate("");
    setRegisterMobileNo("");
    setUniqueUserAttribute(false);
    setConfirmUserDivOpen(false);
    setSignUpConfirmationCode("");

    //Commons
    setShowPassword(false);

    setResponseStatus("");
    setResponseMessage("");
  };

  const setEmailButtonActive = () => {
    resetInputs();
    setIsEmail(true);
  };

  const setMobileButtonActive = () => {
    resetInputs();
    setIsEmail(false);
  };

  const loadUserSub = async (usernameAttribute) => {
    try {
      setLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      if (
        isEmail
          ? isValidEmail(usernameAttribute)
          : isValidPhone(usernameAttribute)
      ) {
        const user_sub = isEmail
          ? await getUserSubIfEmailIdExistsAndVerified(usernameAttribute)
          : await getUserSubIfPhoneNumberExistsAndVerified(usernameAttribute);
        setUserSub(user_sub);
        setResponseStatus("success");
        setResponseMessage("Please enter your password to sign in");
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async (e, username, password) => {
    e.preventDefault();
    try {
      if (userSub && !isPasswordEmpty(password)) {
        setLoading(true);
        const { isSignedIn } = await signIn({ username, password });

        if (isSignedIn) {
          setResponseStatus("green");
          setResponseMessage("Signed in!");
          handleUserRoleAssignment();
          navigate("/");
        }
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUserRoleAssignment = async () => {
    try {
      const { tokens } = await fetchAuthSession();
      const userGroups = tokens.accessToken.payload["cognito:groups"];
      if (
        userGroups &&
        (userGroups.includes("beta-admin") || userGroups.includes("admin"))
      ) {
        dispatch(updateUserRole("Admin"));
      } else {
        dispatch(updateUserRole("Guest"));
      }
    } catch (error) {
      console.error("Error updating user role", error);
    }
  };

  const isValidRegisterInputs = () => {
    setResponseStatus("");
    setResponseMessage("");

    if (registerPassword !== registerConfirmPassword) {
      setResponseStatus("error");
      setResponseMessage("Passwords does not match.");
      return false;
    }
    if (registerPassword.length < 8 || registerPassword.length > 16) {
      setResponseStatus("error");
      setResponseMessage(
        "Password length should be between 8 and 16 characters."
      );
      return false;
    }
    if (!registerName) {
      setResponseStatus("error");
      setResponseMessage("Name cannot be empty.");
      return false;
    }
    if (registerName.length > 50) {
      setResponseStatus("error");
      setResponseMessage(
        "The length of the name should be less than 50 characters."
      );
      return false;
    }
    if (!nameRegex.test(registerName)) {
      setResponseStatus("error");
      setResponseMessage("Name should contain only alphabets and spaces.");
      return false;
    }
    if (!registerBirthDate) {
      setResponseStatus("error");
      setResponseMessage("Date of birth cannot be empty");
    }
    return true;
  };

  const loadUniqueUserAttribute = async () => {
    try {
      setLoading(true);
      setResponseStatus("");
      setResponseMessage("");

      if (
        isEmail ? isValidEmail(registerEmail) : isValidPhone(registerMobileNo)
      ) {
        const flag = isEmail
          ? await isEmailUnique(registerEmail)
          : await isPhoneNumberUnique(registerMobileNo);

        setUniqueUserAttribute(flag);
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    //handle sign up code.
    try {
      setLoading(true);
      if (isValidRegisterInputs()) {
        const username = isEmail ? registerEmail : registerMobileNo;
        const password = registerPassword;
        const name = registerName.trim();
        const birthdate = registerBirthDate;
        const { isSignUpComplete } = await signUp({
          username,
          password,
          options: {
            userAttributes: {
              name,
              birthdate,
            },
            autoSignIn: true,
          },
        });
        if (!isSignUpComplete) {
          setConfirmUserDivOpen(true);
        }
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage("Error occurred while signing up : " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSignUp = async () => {
    try {
      setLoading(true);
      const username = isEmail ? registerEmail : registerMobileNo;
      const confirmationCode = signUpConfirmationCode;
      const { isSignUpComplete } = await confirmSignUp({
        username,
        confirmationCode,
      });
      if (isSignUpComplete) {
        setResponseStatus("success");
        setResponseMessage("User Registration Successful!");
        await autoSignIn();
        handleUserRoleAssignment();
        navigate("/");
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage("Error confirming sign up : ", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResendConfirmationCode = async () => {
    try {
      const username = isEmail ? registerEmail : registerMobileNo;
      const { deliveryMedium } = await resendSignUpCode({ username });
      setResponseStatus("success");
      setResponseMessage("Confirmation Code is resent via " + deliveryMedium);
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage(
        "Error occurred while resending confirmation code. Please try again."
      );
    }
  };

  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-top-container">
      <div className="login-register-container">
        <div className="toggle-buttons">
          <button
            className={isLogin ? "active" : ""}
            onClick={setLoginButtonActive}
            type="button"
          >
            {appCommonData.appLanguage === "English" ? "Login" : "உள்நுழை"}
          </button>
          <button
            className={!isLogin ? "active" : ""}
            onClick={setRegisterButtonActive}
            type="button"
          >
            {appCommonData.appLanguage === "English"
              ? "Register"
              : "பதிவு செய்"}
          </button>
        </div>
        {isLogin ? (
          <form className="login-form">
            <div className="login-register-choice-container">
              <LogoSection />
              <h6>
                {appCommonData.appLanguage === "English"
                  ? "Login using "
                  : "உள்நுழைய பயன்படுத்த வேண்டியது ?"}
              </h6>
              <div className="login-choice-buttons">
                <button
                  className={isEmail ? "active" : ""}
                  id="login-choice-button"
                  onClick={setEmailButtonActive}
                  type="button"
                >
                  {appCommonData.appLanguage === "English"
                    ? "Email ID"
                    : "மின்னஞ்சல் முகவரி"}
                </button>
                <button
                  className={!isEmail ? "active" : ""}
                  id="login-choice-button"
                  onClick={setMobileButtonActive}
                  type="button"
                >
                  {appCommonData.appLanguage === "English"
                    ? "Mobile No"
                    : "அலைபேசி எண்"}
                </button>
              </div>
            </div>
            <div className="form-group">
              <label>
                {isEmail
                  ? appCommonData.appLanguage === "English"
                    ? "Email ID"
                    : "மின்னஞ்சல் முகவரி"
                  : appCommonData.appLanguage === "English"
                  ? "Mobile No"
                  : "அலைபேசி எண்"}
                {isEmail ? (
                  <input
                    type="email"
                    value={loginEmail}
                    onChange={(e) => setLoginEmail(e.target.value)}
                    disabled={userSub ? true : false}
                  />
                ) : (
                  <PhoneInput
                    defaultCountry="in"
                    value={loginPhone}
                    onChange={(phone) => setLoginPhone(phone)}
                    disabled={userSub ? true : false}
                  />
                )}
              </label>
              {userSub && (
                <div className="password-div">
                  <label>
                    {appCommonData.appLanguage === "English"
                      ? "Password"
                      : "கடவுச்சொல்"}
                    <input
                      type={showPassword ? "text" : "password"}
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                    />
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value={showPassword}
                      checked={showPassword}
                      onChange={handleShowPasswordChange}
                    />
                    {appCommonData.appLanguage === "English"
                      ? " Show password"
                      : " கடவுச்சொல்லை காட்டவும்"}
                  </label>
                </div>
              )}
              <br />
              <Button
                type="button"
                variant="light"
                as={Link}
                to="/resetPassword"
              >
                {appCommonData.appLanguage === "English"
                  ? "Forgot password ?"
                  : "கடவுச்சொல்லை மறந்துவிட்டீர்களா ?"}
              </Button>
            </div>
            <button
              type="button"
              onClick={(e) => {
                userSub
                  ? handleSignIn(e, userSub, loginPassword)
                  : loadUserSub(isEmail ? loginEmail : loginPhone);
              }}
            >
              {userSub
                ? appCommonData.appLanguage === "English"
                  ? "Login"
                  : "உள்நுழை"
                : appCommonData.appLanguage === "English"
                ? "Next"
                : "தொடரவும்"}
            </button>
            {isLoading && (
              <div>
                <Loader variation="linear" filledColor="brown" />
              </div>
            )}
            {responseMessage && (
              <div className="response-message-container">
                <h6
                  style={{
                    color: responseStatus === "success" ? "green" : "red",
                  }}
                >
                  {responseMessage}
                </h6>
              </div>
            )}
          </form>
        ) : (
          <form className="register-form">
            <div className="login-register-choice-container">
              <LogoSection />
              <h6>
                {appCommonData.appLanguage === "English"
                  ? "User Registration"
                  : "பயனர் பதிவு"}
              </h6>
              <div className="login-choice-buttons">
                <button
                  className={isEmail ? "active" : ""}
                  id="login-choice-button"
                  onClick={setEmailButtonActive}
                  type="button"
                >
                  {appCommonData.appLanguage === "English"
                    ? "Email ID"
                    : "மின்னஞ்சல் முகவரி"}
                </button>
                <button
                  className={!isEmail ? "active" : ""}
                  id="login-choice-button"
                  onClick={setMobileButtonActive}
                  type="button"
                >
                  {appCommonData.appLanguage === "English"
                    ? "Mobile No"
                    : "அலைபேசி எண்"}
                </button>
              </div>
            </div>
            <label>
              {isEmail
                ? appCommonData.appLanguage === "English"
                  ? "Email ID (mandatory)"
                  : "மின்னஞ்சல் முகவரி (கட்டாயம்)"
                : appCommonData.appLanguage === "English"
                ? "Mobile No (mandatory)"
                : "அலைபேசி எண் (கட்டாயம்)"}
              {isEmail ? (
                <input
                  type="email"
                  value={registerEmail}
                  onChange={(e) => setRegisterEmail(e.target.value)}
                  style={{ marginBottom: "10px" }}
                  disabled={isUniqueUserAttribute ? true : false}
                />
              ) : (
                <PhoneInput
                  defaultCountry="in"
                  value={registerMobileNo}
                  onChange={(phone) => setRegisterMobileNo(phone)}
                  disabled={isUniqueUserAttribute ? true : false}
                />
              )}
            </label>
            {isUniqueUserAttribute && !isConfirmUserDivOpen && (
              <div className="registeration-attributes-div">
                <label>
                  {appCommonData.appLanguage === "English"
                    ? "Password"
                    : "கடவுச்சொல்"}
                  <input
                    type={showPassword ? "text" : "password"}
                    value={registerPassword}
                    onChange={(e) => setRegisterPassword(e.target.value)}
                  />
                </label>
                <label>
                  {appCommonData.appLanguage === "English"
                    ? "Confirm password"
                    : "கடவுச்சொல்லை உறுதிப்படுத்தவும்"}
                  <input
                    type={showPassword ? "text" : "password"}
                    value={registerConfirmPassword}
                    onChange={(e) => setRegisterConfirmPassword(e.target.value)}
                  />
                </label>
                <label>
                  <input
                    type="checkbox"
                    value={showPassword}
                    checked={showPassword}
                    onChange={handleShowPasswordChange}
                  />
                  {appCommonData.appLanguage === "English"
                    ? " Show password"
                    : " கடவுச்சொல்லை காட்டவும்"}
                </label>
                <hr />
                <label>
                  {appCommonData.appLanguage === "English" ? "Name" : "பெயர்"}
                  <input
                    type="text"
                    value={registerName}
                    onChange={(e) => setRegisterName(e.target.value)}
                  />
                </label>
                <label>
                  {appCommonData.appLanguage === "English"
                    ? "Date of Birth"
                    : "பிறந்த தேதி"}
                  <input
                    type="date"
                    value={registerBirthDate}
                    onChange={(e) => setRegisterBirthDate(e.target.value)}
                  />
                </label>
              </div>
            )}
            {!isConfirmUserDivOpen && (
              <button
                type="button"
                onClick={
                  isUniqueUserAttribute ? handleSignUp : loadUniqueUserAttribute
                }
              >
                {isUniqueUserAttribute
                  ? appCommonData.appLanguage === "English"
                    ? "Register"
                    : "பதிவு செய்"
                  : appCommonData.appLanguage === "English"
                  ? "Next"
                  : "தொடரவும்"}
              </button>
            )}
            {isConfirmUserDivOpen && (
              <div className="confirm-user-div">
                <label>
                  {isEmail
                    ? appCommonData.appLanguage === "English"
                      ? "Please enter the verification code sent to your email address."
                      : "உங்கள் மின்னஞ்சல் முகவரிக்கு அனுப்பப்பட்ட சரிபார்ப்புக் குறியீட்டை உள்ளிடவும்."
                    : appCommonData.appLanguage === "English"
                    ? "Please enter the verification code sent to your phone number."
                    : "உங்கள் அலைபேசி எண்ணுக்கு அனுப்பப்பட்ட சரிபார்ப்புக் குறியீட்டை உள்ளிடவும்."}
                  <input
                    type="text"
                    value={signUpConfirmationCode}
                    onChange={(e) => setSignUpConfirmationCode(e.target.value)}
                  />
                </label>
                <button
                  type="button"
                  variant="custom"
                  id="btn-custom-secondary"
                  onClick={handleResendConfirmationCode}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Resend"
                    : "மீண்டும் அனுப்பு"}
                </button>
                <button
                  type="button"
                  variant="custom"
                  id="btn-custom-primary"
                  onClick={handleConfirmSignUp}
                  disabled={!signUpConfirmationCode}
                >
                  {appCommonData.appLanguage === "English"
                    ? "Verify"
                    : "சரிபார்க்கவும்"}
                </button>
              </div>
            )}
            {isLoading && (
              <div>
                <Loader variation="linear" filledColor="brown" />
              </div>
            )}
            {responseMessage && (
              <div className="response-message-container">
                <h6
                  style={{
                    color: responseStatus === "success" ? "green" : "red",
                  }}
                >
                  {responseMessage}
                </h6>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
